<template>
  <div class="grid my-5">
    <div>
        <Toast />

        <div class="bg-white p-4 rounded-md">
            <Steps :model="items" :readonly="false" />
        </div>

        <router-view v-slot="{Component}" class="mt-4" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import Steps from 'primevue/steps'
import derechosPeticionStore from '../../../../store/derechosPeticion.store'
import localizacionStore from '../../../../store/localizacion.store'
export default {
  name: 'ProcesosJuridicosTutelas',
  components: { Steps },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const items = ref([
      // {
      //   label: 'Buscar persona',
      //   to: 'buscar-persona'
      // },
      {
        label: 'Editar persona',
        to: 'datos-persona'
      },
      {
        label: 'Información del Derecho de Petición',
        to: 'datos-derecho-peticion'
      }
    ])
    const formObject = ref({})
    const nextPage = (event) => {
      for (const field in event.formData) {
        formObject.value[field] = event.formData[field]
      }

      router.push(items.value[event.pageIndex + 1].to)
    }
    const prevPage = (event) => {
        router.push(items.value[event.pageIndex - 1].to)
    }
    const complete = () => {
        toast.add({ severity: 'success', summary: 'Order submitted', detail: 'Dear, ' + formObject.value.firstname + ' ' + formObject.value.lastname + ' your order completed.' })
    }
    onMounted(() => {
      derechosPeticionStore.dispatch('find', route.params.id)
      localizacionStore.dispatch('getDeparatamentos')
    })
    return {
      items,
      formObject,
      nextPage,
      prevPage,
      complete
    }
  }
}
</script>
<style scoped>
</style>
